import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function WomenJeansPage() {
  return (
    <CategoryWrapper
      seoTitle="Bukser"
      gender="dame"
      category="jeans"
    />
  )
}

export default WomenJeansPage
